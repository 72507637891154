<template>
  <div id="landing0324" class="b">
    <section
      class="img-container"
      style="background-image: url('/imgs/landing_0324/summer_packages.jpg')"
    >
      <div class="container pt-5">
        <nav>
          <router-link class="nav-container" to="/">
            <img
              src="/svgs/logo_4.svg"
              alt="Logo NLC"
              class="logo_2 logo-nlc"
            />
          </router-link>
        </nav>
      </div>

      <div class="container py-7 text-white">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-7">
            <h1 class="title h3">
              {{ lang.title }}
            </h1>
            <h5>{{ lang.from }}</h5>
            <div class="price">
              <span class="number">
                {{ lang.price }}
              </span>
              <span class="currency">{{ lang.currency }}</span>
            </div>
            <h5 class="mb-5 subtitle">
              {{ lang.subtitle }}
            </h5>
          </div>

          <form
            id="form"
            @submit.prevent="sendForm"
            class="col-md-6 col-lg-4 offset-lg-1"
          >
            <div class="text-center">
              <h4 class="form-title">{{ $lang("form.title") }}</h4>
              <h6 class="mb-5 form-subtitle">{{ $lang("form.subtitle") }}</h6>
            </div>

            <input
              type="text"
              :placeholder="$lang('form.name')"
              v-model="form.name"
              required
            />
            <input
              type="text"
              :placeholder="$lang('form.email')"
              v-model="form.email"
              required
            />
            <input
              type="text"
              :placeholder="$lang('form.phone')"
              v-model="form.phone"
              required
            />
            <p>
              <small>{{ $lang("form.note") }}</small>
            </p>

            <div class="button-container">
              <button class="btn btn-primary form-button" type="submit">
                {{ $lang("form.button") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section class="py-7">
      <div class="container gallery">
        <div class="row">
          <div class="col-md-4">
            <h5>{{ lang.packages.title }}</h5>
            <p>
              <small>{{ lang.packages.subtitle }}</small>
            </p>
          </div>
          <div class="col-md-8">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_01.jpg')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_02.jpg')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_03.jpg')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_04.jpg')"
            />
          </div>
          <div class="col-md-8">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_05.jpg')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_06.jpg')"
            />
          </div>
          <div class="col-md-4">
            <h5>{{ lang.packages.foot }}</h5>
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_07.jpg')"
            />
          </div>
          <div class="col-md-4">
            <div
              class="img-container img-container-rounded thumb"
              style="background-image: url('/imgs/landing_0324/sp_08.jpg')"
            />
          </div>
        </div>
      </div>
    </section>

    <section
      class="py-7 img-container minh-9 text-white d-flex align-items-center"
      style="background-image: url('/imgs/landing_0324/footer_18.jpg')"
    >
      <div class="container text-center">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <h3 class="mb-4">{{ lang.get_ready.title }}</h3>
            <h5 class="mb-4">{{ lang.get_ready.subtitle }}</h5>
            <button class="btn btn-primary px-5">
              {{ lang.get_ready.button }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-md-4 d-flex justify-content-center justify-content-md-start mb-4 mb-md-0"
          >
            <img src="/svgs/logo_2.svg" alt="Logo NLC" class="logo-nlc" />
          </div>
          <div class="col-md-4 d-flex flex-column gap-2 align-items-center">
            <a href="http://nlcollege.es" target="_blank"> nlcollege.es </a>
            <a href="mailto:info@nlcollege.es" target="_blank">
              info@nlcollege.es
            </a>

            <div class="socials">
              <a
                href="https://www.instagram.com/nlcollege.spain/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="https://ne-np.facebook.com/nlcollege/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.tiktok.com/@nlcollege.spain"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-tiktok"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/nlcollege-spain/mycompany/"
                target="_blank"
                class="btn btn-white btn-rounded btn-sm"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div
            class="col-md-4 d-flex justify-content-md-end justify-content-center mt-4 mt-md-0"
          >
            <p>© 2024</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import axios from "axios";

export default {
  data: () => ({
    form: {
      name: null,
      email: null,
      phone: null,
    },
    countries_en,
    countries_es,
  }),
  computed: {
    lang() {
      return this.$lang("summer_packages", true);
    },
  },
  methods: {
    goToForm(id) {
      document.querySelector(id).scrollIntoView();
    },
    sendForm() {
      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-phone": this.form.phone,
            "your-email": this.form.email,
            "your-name": this.form.name,
            token: "bso1re2302yv41311195f2z469422h9lj",
          },
        })
        .then(() => {
          this.$router.push({ name: "thanks" });
        });
    },
  },
};
</script>

<style></style>
